/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@400;500;600;700;800;900&display=swap');
:root {
  --chakra-colors-main-theme: #ff136d;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins', sans-serif;
}
.App {
  text-align: center;
}
body,
.wrapper {
  min-height: 100vh;
  position: relative;
  background-color: #121212;
  color: white;
}
.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}
.wrapper > div {
  background: #121212;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.icon-border {
  padding: 5px;
  border: 2px solid #f4f4f4;
  border-radius: 50%;
}

.token-tick {
  color: white;
  background-color: #14a688;
}

.token-page-body {
  background: white;
  text-align: left;
  border-radius: 5px;
  position: absolute;
  top: 200px;
}

.p_icon {
  max-width: 30px;
  height: 18px;
  margin-right: 10px;
}

.p_title {
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .wallet-connected-button {
  background-color: linear-gradient(261.87deg, #ff136d 13.12%, #e186a9 83.57%);
} */

.side-flex:hover .side-link {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-img {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.reservation-info {
  width: 25px;
  height: 25px;
}

.crypto-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-btn {
  margin-right: 5px;
  border: 1px solid black !important;
}

.cancel-btn:hover {
  bg: white !important;
  color: black;
  border: 1px solid black !important;
}

/* .border-hovr {
  border-image: linear-gradient(#178bff, #df48b3) 30;
  border-width: 1px;
  border-style: solid;
} */

.required {
  color: red;
}

.forgot-pass-form {
  background: #ffff !important;
  color: black;
}
@font-face {
  font-family: 'Porter Sans';
  src: url('/public/fonts/porter-sans-inline-block.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.gradient-text {
  background: linear-gradient(90deg, #453FD6 0%, #FF136D 100%);
  -webkit-background-clip: text;
  color: transparent;
}
